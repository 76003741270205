/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Tags } from "@tryghost/helpers-gatsby"
import { readingTime as readingTimeHelper } from "@tryghost/helpers"

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    const elipsis = (str) => {
        const length = 100

        const myRegex = /\[.+\]/g // regex pour supprimer ce qu'il y a entre crochets (les liens)
        str = str.replace(myRegex, ``)

        return str.substring(0, length) + `...`
    }

    return (
        <Link to={url} className="post-card">
            <header className="post-card-header">
                {post.feature_image && (
                    <div
                        className="post-card-image"
                        style={{
                            backgroundImage: `url(${post.feature_image})`,
                        }}
                    ></div>
                )}
                {post.tags && (
                    <div className="post-card-tags">
                        {` `}
                        <Tags
                            post={post}
                            visibility="public"
                            autolink={false}
                        />
                    </div>
                )}
                {post.featured && <span>Featured</span>}
                {post.tags && post.tags[0].name === `FLASH BRUXELLOIS` ?
                    <React.Fragment >

                        <h2 className="post-card-title">
                            {post.title.indexOf(`(`) !== -1
                                ? post.title.substr(0, post.title.indexOf(`(`))
                                : post.title}
                        </h2>
                        <h3 className="post-card-title">
                            {post.title.indexOf(`(`) !== -1
                                ? post.title.substr(
                                    post.title.indexOf(`(`),
                                    post.title.indexOf(`)`)
                                )
                                : post.title}
                        </h3>
                    </React.Fragment>

                    :
                    <h2 className="post-card-title" style={{ height: `auto` }}>
                        {post.title}
                    </h2>
                }

            </header>
            <section className="post-card-excerpt">
                {elipsis(post.excerpt)} <b>lire la suite</b>
            </section>
            <footer className="post-card-footer">
                <div className="post-card-footer-left">
                    <span>{post.published_at_pretty}&nbsp;&nbsp;</span>
                    {/*Pas possible de traduire le readingTime qui provient du SDK de Ghost, du coup je coupe le read */}
                    <span>&#9679;&nbsp;&nbsp;{readingTime.substring(0, readingTime.indexOf(`r`))}</span>
                </div>
            </footer>
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
