import React from 'react'
import PropTypes from 'prop-types'

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    EmailShareButton,
    EmailIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from 'react-share'

const ShareButtons = ({ url, title, twitterHandle, tags, size, className, round }) => (
    <div className={className}>
        <FacebookShareButton url={url} >
            <FacebookIcon size={size} round={round} borderRadius={3}/>
        </FacebookShareButton>

        <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
            <TwitterIcon size={size} round={round} />
        </TwitterShareButton>

        <LinkedinShareButton url={url} >
            <LinkedinIcon size={size} round={round}/>
        </LinkedinShareButton>

        <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon size={size} round={round}/>
        </WhatsappShareButton>

        <EmailShareButton url={url} title={title} hashtags={tags}>
            <EmailIcon size={size} round={round} />
        </EmailShareButton>
    </div>
)

ShareButtons.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    twitterHandle: PropTypes.string.isRequired,
    tags: PropTypes.array,
    size: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
    round: PropTypes.bool.isRequired,
}

ShareButtons.defaultProps = {
    url: `https://reflexions-dexpat.com/`,
    title: `Réflexions d'un expatriés`,
    size: 50,
    round: true,
    twitterHandle: `wolf_starke`,
}

export default ShareButtons
